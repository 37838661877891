<template>
  <div class="RightBox" :style="'background-image:url('+url+')'">
<!--    <img :src="url" width="100%" height="100%" alt="">-->
<!--    <div class="nav_top_log">-->
<!--      <img src="/img/logo.png" alt="">-->
<!--    </div>-->
  </div>
</template>

<script>
// import store from '@/store'
// import logo from "@/layout/components/Sidebar/Logo";

export default {
  name: "RightImg",
  // props:['url'],
  data(){
    return {
      // :style="'background-image:url('+url+')'"
      url:'',
      route:'',
    }
  },
  watch:{
    $route(to,form) {
      // setTimeout(()=>{
      //   console.log(sessionStorage.getItem('RightImg'))
      //   this.url = sessionStorage.getItem('RightImg')
      // },10)
      // this.number++
      console.log('侧边',to,form)
      // switch (to.path){
      //   case '/vacation/vacation':
      //     this.url='/img/swiperPage/DTZXCBT.png';
      //     break;
      //   case '/playturn/playturnvr':
      //     this.url='/img/swiperPage/VRYYCBT.png';
      //     break;
      //   case '/hotelHomestay/hotelHomestay':
      //     this.url='/img/swiperPage/JDMSCBT.png';
      //     break;
      //   case '/travelStrategy/travelStrategy':
      //     this.url='/img/swiperPage/LLGLCBT.png';
      //     break;
      //   default:
      //     this.url='/img/swiperPage/DTZXCBT.png';
      // }
      // if(to.query.type){
      //   if(form.query.type != to.query.type){
      //     this.navid = to.query.type
      //   }
      // }else{
      //   this.navid = '0'
      // }
      // this.$router.go(0)
    },
    // deep: true
  },
  computed:{

    // url:function() {
    //   // 通过vuex的getters方法来获取state里面的数据
    //   // return this.$store.getters.setImgUrl;
    //   return sessionStorage.getItem('RightImg');
    //   // console.log(this.$store.getters.imgUrl);
    // }
  },
  components:{

  },
  created() {
    this.route=this.$route.fullPath
    setTimeout(()=>{
      console.log(sessionStorage.getItem('RightImg'))
      this.url = sessionStorage.getItem('RightImg')
    },10)
  },
  mounted() {
    // this.$nextTick(()=>{
    //   this.url=store.state.app.leftimg
    //   console.log(this.url)
    // })
    console.log()
  },
  methods:{

  }
}
</script>

<style scoped lang="less">
.RightBox{
  transition:all 500ms ease;
  width: 303px;
  height: 100%;
  margin-left: -3px;
  background-repeat: no-repeat;
  background-size: cover;
  float: left;
}
.nav_top_log{
  width: 210px;
  height: 90px;
  margin-left: 40px;
  background-color: #FFFFFF;
  img{
    width: 96%;
    height: 96%;
    margin: auto;
    display: block;
  }
}

</style>
