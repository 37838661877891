<template>
  <div :class="{'has-logo':showLogo}">
    <logo v-if="showLogo" :collapse="isCollapse" />
    <el-scrollbar wrap-class="scrollbar-wrapper" v-show="!ishow">
      <el-menu
         class="nav"
        :default-active="activeMenu"
        :collapse="isCollapse"
        :background-color="variables.menuBg"
        :text-color="variables.menuText"
        :unique-opened="false"
        :active-text-color="variables.menuActiveText"
        :collapse-transition="false"
        mode="horizontal"
      >
<!--        <div class="radius">-->
<!--          <div style="width: 100%;height: 50px;background: #3D8B7D;border-radius: 0 0px 0 0"></div>-->
<!--        </div>-->
<!--        <el-menu-item index="1" :class="biaoshibtn==1?'is-active':''"><div :style="biaoshibtn==1?{color:'#007B8A'}:{color:'black'}" @click="$router.push({path:'/'})" class="website_home">&nbsp;首&nbsp; &nbsp;页</div></el-menu-item>-->
        <sidebar-item @click="asd" v-for="route in routerList" :key="route.path" :item="route" :textType="zhen" :base-path="route.path" />
        <div class="titleicon">
          <div class="titleicon_1" @click="tousubtn(3)"></div>
          <div class="H">/</div>
<!--          <div class="titleicon_2" @click="tousubtn(2)"></div>-->
<!--          <div class="titleicon_3" @click="tousubtn(1)"></div>-->
          <div :class="zhen==0?'zhong titleicon_4':'wei titleicon_5'" @click="cutText(0)" class="">中</div>
          <div :class="zhen==1?'zhong titleicon_4':'wei titleicon_5'" @click="cutText(1)" class="">EN</div>

        </div>
      </el-menu>

    </el-scrollbar>

    <div class="searchBox" v-if="ishow">
      <div class="inp">
        <el-input v-model="searchValue" :placeholder="zhen == 1 ? 'Please enter the content' : '请输入内容'" @change.native="searchFun" prefix-icon="el-icon-search"></el-input>
      </div>
      <div class="btns">
        <!--            <span :class="{'el-icon-circle-close':true,'btnColor':searchValue!=''}" @click="searchValue = ''"></span>-->
        <span class="el-icon-circle-close" @click="closeAlert(false)"></span>
      </div>
    </div>

<!--    <alertInfo @closeAlert="closeAlert" :type="type" v-if="ishow"></alertInfo>-->
  </div>
</template>

<style scoped lang="less">
.searchBox{
  display: flex;
  align-items: center;
  height: 70px;
  overflow: hidden;
  margin-right: 220px;
  .inp{
    width: 860px;
    height: 100%;
    margin-right: 120px;
    .el-input{
      height: 100%;
      /deep/input{
        height: 100%;
        background: #F5F5F5;
        border-radius: 35px;
        padding-left: 95px;
        border: none;
        font-size: 20px;
        &::-webkit-input-placeholder{/*Webkit browsers*/
          color:#999;
          font-size:20px;
        }
        &:-moz-placeholder{/*Mozilla Firefox 4 to 8*/
          color:#999;
          font-size:20px;
        }
        &::moz-placeholder{/*Mozilla Firefox 19+*/
          color:#999;
          font-size:20px;
        }
        &:-ms-input-placeholder{/*Internet Explorer 10+*/
          color:#999;
          font-size:20px;
        }
      }
    }
    /deep/.el-input__prefix{
      height: inherit;
      padding: 20px 30px;
      .el-input__icon{
        font-size: 30px;
        line-height: normal;
      }
    }
  }
  .btns{
    span{
      font-size: 30px;
      margin-left: 20px;
      color: #ffffff;
      &:hover{
        //color: #337D8D;
      }
      cursor: pointer;
    }
    .btnColor{
      color: #337D8D;
    }
  }
}
</style>
<style scoped lang="less">
.nav{
  height: 70px;
  //background: #fff!important;
  background: rgba(0,0,0,0)!important;
  .el-menu-item{
    height: 70px;
    .website_home{
      height: 70px;
      line-height: 72px;
    }
  }
}

.titleicon{
  display: flex;
  //margin-top: 13px;
  margin-left: 60px;
  cursor: pointer;
  align-items: center;
  .titleicon_1{
    width: 35px;
    height: 35px;
    margin: 0 0px;
    border-radius: 50%;
    //border: 1px solid #666666;
    background: url("../../../assets/img/SSH.png") no-repeat;
    background-size: 100%;
    //background-position: 7px 7px;

    &:hover{
      background: url("../../../assets/img/SSBS.png") no-repeat;
      background-color: #AAAAAA;
      //background-position: 7px 7px;
      background-size: 100%;
      border: 1px solid #FFFFFF;
    }
  }
  .H{
    font-size: 20px;
    font-family: PingFangSC-Semibold, PingFang SC;
    font-weight: 600;
    color: #333333;
    margin: 0 18px;
  }
  .titleicon_2{
    width: 34px;
    height: 34px;
    margin-left: 0;
    border-radius: 50%;
    border: 1px solid #666666;
    background: url("../../../assets/img/KF.png") no-repeat;
    background-size: 60%;
    background-position: 7px 7px;
    &:hover{
      background: url("../../../assets/img/KFBS.png") no-repeat;
      background-color: #AAAAAA;
      background-position: 7px 7px;
      background-size: 60%;
      border: 1px solid #FFFFFF;
    }
  }
  .titleicon_3{
    width: 34px;
    height: 34px;
    margin-left: 18px;
    border-radius: 50%;
    border: 1px solid #666666;
    background: url("../../../assets/img/TS.png") no-repeat;
    background-position: 15px 5px;
    background-size: 3px 20px;
    &:hover{
      background: url("../../../assets/img/TSBS.png") no-repeat;
      background-color: #AAAAAA;
      background-position: 15px 5px;
      background-size: 3px 20px;
      border: 1px solid #FFFFFF;
    }
  }
  .titleicon_4{
    width: 34px;
    height: 34px;
    margin-left: 0;
    border-radius: 8px;
    border: 1px solid #007B8A;
    text-align: center;
    line-height: 34px;
    //background: #007B8A;
    &:hover{
      background: #007B8A;
      color: #ffffff;
    }
  }
  .wei{
    //color: #FFFFFF;
    //background:#337D8D ;
    color: #000000;
    background: #FFFFFF;
    border-color: #333;
  }
  .zhong{
    color: #337D8D;
    background: #FFFFFF;
  }
  .titleicon_5{
    width: 34px;
    height: 34px;
    margin-left: 18px;
    margin-right: 10px;
    border-radius: 8px;
    border: 1px solid #666666;
    text-align: center;
    line-height: 34px;
    &:hover{
      border: 1px solid #007B8A;
      background: #007B8A;
      color: #ffffff;
    }
  }
}
.website_home{
  font-size: 20px;
  font-weight: 700;
  //margin-top: 3px;
  border: none;
  font-family: PingFangSC-Semibold, PingFang SC;
}
.radius{
  background: #EFF2FF;
}
/deep/.el-menu--collapse .radius{
  background: #0C2480;
}
/deep/.nest-menu{
  .submenuPadding{
    padding-left: 40px !important;
  }
}

/deep/.el-menu{
  display: flex;
  &.el-menu--horizontal{
    border-bottom: none;
  }
}
.el-menu--horizontal>.el-menu-item{
  //background: #fff!important;
  background: rgba(0,0,0,0)!important;
  height: 100%;

  &:hover{
    //background: none !important;
    &>div{
      color: rgb(0, 123, 138) !important;
    }

  }
}

.nav /deep/.el-menu-item,/deep/.el-submenu .el-submenu__title{
  //background-color: #FFFFFF !important;
  background-color: rgba(0,0,0,0)!important;
  font-size: 20px !important;
  font-family: PingFangSC-Semibold, PingFang SC;
  font-weight: 600;
  color: #333333;
  /*line-height: 70px;*/
  padding: 0 20px;
  padding-left: 53px !important;
  &:hover{
    color: #007B8A !important;
    //background-image: url("../../../assets/img/navLeftBg.png") !important;
    background-image: url("../../../assets/img/navBg3_2.png") !important;
    background-size: 49px 24px !important;
    background-repeat: no-repeat;
    background-position: left calc(50% + 8px);
  }
}
.nav{
  /deep/div>.submenu,/deep/div>.el-menu-item{
    .el-submenu__title{
      height: 70px;
      line-height: 70px;
    }
  }
  /deep/div .el-menu-item{
    height: 70px;
    line-height: 70px;
  }
  /deep/.is-active{
    //background-image: url("../../../assets/img/navLeftBg.png") !important;
    ////background-size: auto 100% !important;
    //background-size: 40px 6px !important;
    //background-repeat: no-repeat;
    //background-position: left calc(50% + 5px);
    border: none;
    &.submenuPadding{
      //background-image: url("../../../assets/img/navLeftBg.png") !important;
      background-image: url("../../../assets/img/navBg3_2.png") !important;
      //background-size: auto 100% !important;
      background-size: 49px 24px !important;
      background-repeat: no-repeat;
      background-position: left calc(50% + 8px);
    }
    .el-submenu__title{
      //background-image: url("../../../assets/img/navLeftBg.png") !important;
      background-image: url("../../../assets/img/navBg3_2.png") !important;
      //background-size: auto 100% !important;
      background-size: 49px 24px !important;
      background-repeat: no-repeat;
      background-position: left calc(50% + 8px);
    }
  }
}
/deep/.el-scrollbar__bar.is-vertical{
  height: auto !important;
  display: none;
}
/deep/.el-scrollbar{
  height: 70px;
  .el-scrollbar__wrap{
    overflow: hidden !important;
    margin: 0 !important;
  }
}
</style>
<script>
import {permissionList} from '@/api/user'
import { mapGetters } from 'vuex'
import Logo from './Logo'
import SidebarItem from './SidebarItem'
import variables from '@/styles/variables.scss'
import {thisUser} from "@/api/index";
import alertInfo from '@/components/alertInfo'
export default {
  components: { SidebarItem, Logo,alertInfo },
  created() {
    // sessionStorage.setItem('textType',this.zhen==1?'EN':'CH')
    if(sessionStorage.getItem('textType')){
      this.zhen = sessionStorage.getItem('textType') == 'EN'? 1 : 0
    }else{
      sessionStorage.setItem('textType','CH')
    }

    let route=this.$router.options.routes
    // // route[0].path='/';
    // this.getList()
    this.routerList=route
    console.log('路由列表',this.routerList)
    // if(route[0].path){
    //   this.$router.push({
    //     path:`${route[0].path}${route[0].path}`,
    //   })
    // }
    // this.getQuan()
    // console.log(this.$router.options.routes.splice(5,1)[0])
    // console.log(this.$route.path)
  },
  data(){
    return{
      routerList:[],
      list:[],
      green:'#007B8A',
      black:'#000000',
      zhen:0,
      ishow:false,
      type:1,
      searchValue:''
    }
  },
  watch:{
    zhen(val) {
      // this.value = this.demo;
      // console.log('监听的值',val)
      // this.$router.go(0)
      sessionStorage.setItem('textType',val==1?'EN':'CH')
    }
  },
  methods:{
    cutText(num){
      this.zhen = num
      this.$router.go(0)
    },
    searchFun(){
      // this.searchValue
      this.$router.push({path:'/searchPage/searchPage',query:{value:this.searchValue}})
      this.ishow = false
      this.searchValue = ''
    },
    closeAlert(bool){
      // console.log(bool)
      this.searchValue = ''
      this.ishow = bool
    },
    tousubtn(num){
      // this.type=num
      // console.log(this.$route.path)
      if(this.$route.path == '/searchPage/searchPage'){    // 当前已经是搜索页面
        return false
      }else{
        this.ishow=true
      }
    },
    asd(){
      console.log(123)
    },
    async getList(){
      var pageArr = JSON.parse(sessionStorage.getItem('Permission'))
      let route=this.$router.options.routes
      // console.log('去重',Array.from(new Set(pageArr)))
      // pageArr.forEach((item,index)=>{
      //   if(item == 'ComplaintTypeList'){
      //     pageArr.splice(index,1)
      //   }
      // })
      // route = Array.from(new Set(route))
      this.routerList=route
      var arr = [route[0],route[1],route[route.length-1]]
      pageArr.forEach((item,index)=>{
        this.routerList.forEach((items,indexs)=>{
          if(items.children){
            items.children.forEach((itemss,indexss)=>{
              if(itemss.name == item){
                // console.log(itemss)
                // if(itemss.name == 'ComplaintTypeList'){
                //
                // }else{
                  arr.push(items)
                // }

              }

            })
          }

        })
      })
      console.log(123,this.$route.fullPath)
      this.routerList = arr
      // await permissionList({}).then(res=>{
      //   // console.log(666,res)
      //   if(res.result == 0){
      //     sessionStorage.setItem('BtnPermission',JSON.stringify(res.data))
      //     let route=this.$router.options.routes
      //     this.routerList=route
      //     this.list = res.data
      //     var arr = [this.routerList[0],this.routerList[1]]
      //     this.routerList.forEach((item,index)=>{
      //       // console.log(item.path.substring(1))
      //       this.list.forEach((items,indexs)=>{
      //         if(item.path.substring(1) == items){
      //           arr.push(item)
      //         }
      //       })
      //     })
      //     console.log('权限',arr)
      //     this.routerList = arr
      //   }
      // })
    },
    // async getQuan(){
    //   const res=await thisUser({})
    //   // console.log(res)
    //   if(res.code===0){
    //     let routerLists=[]
    //     let route=this.$router.options.routes
    //     // console.log(route)
    //     for(let i=0;i<route.length;i++){
    //       if(route[i].path==="/" || route[i].path==="*" || route[i].path==='/404'){
    //         routerLists.push(route[i])
    //         route.splice(i,1)
    //
    //         // console.log(route[i])
    //         i--
    //       }
    //     }
    //     // console.log(route)
    //     route.forEach((item,index)=>{
    //       res.data.childern.forEach((item1,index)=> {
    //         if(item.meta){
    //           if (item.meta.title===item1.title) {
    //             // console.log(item)
    //             routerLists.push(item)
    //
    //           }
    //         }else{
    //           item.children.forEach((item2,index2)=>{
    //             if (item2.meta.title===item1.title) {
    //               // console.log(item)
    //               routerLists.push(item)
    //             }
    //           })
    //         }
    //
    //
    //       })
    //
    //     })
    //     this.routerList=routerLists
    //     // this.$router.push('/approve/approve-getPark-list')
    //     // console.log(routerLists)
    //     window.sessionStorage.setItem("router",JSON.stringify(this.routerList));
    //   }
    //
    //
    // }
  },
  computed: {
    ...mapGetters([
      'sidebar'
    ]),
    biaoshibtn() {
      return this.$route.fullPath=="/"?1:2

    },
    routes() {
      return this.$router.options.routes
    },
    activeMenu() {
      const route = this.$route
      const { meta, path } = route
      // if set path, the sidebar will highlight the path you set
      if (meta.activeMenu) {
        return meta.activeMenu
      }
      return path
    },
    showLogo() {
      return this.$store.state.settings.sidebarLogo
    },
    variables() {
      return variables
    },
    isCollapse() {
      // return !this.sidebar.opened
      return false
    }
  }
}
</script>
