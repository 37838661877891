import {get,post,Del,put} from '@/utils/request'
export const imgURL ='https://zhoukou.qukchina.com/zhoukou/files/'; //图片
export const fileURL ='https://gonganziyuan.qukchina.com/'; //上传
export const upfileURL ='https://gonganadmin.qukchina.com/'; //上传

//账户管理
export const userList=(data)=>get(`account/listInfo`,data) //账户列表
export const userSave=(data)=>post(`account`,data) //保存账户
export const userInfo=(accountId,data)=>get(`account/${accountId}/editInfo`,data) //账户信息
export const userEdit=(accountId,data)=>put(`account/${accountId}`,data) //账户修改
export const userDel=(accountId,data)=>Del(`account/${accountId}`,data) //账户删除
export const resetPassword=(accountId,data)=>put(`account/${accountId}/resetPassword`,data) //重置密码
export const enable=(accountId,data)=>put(`account/${accountId}/enable`,data) //账户启用
export const disable=(accountId,data)=>put(`account/${accountId}/disable`,data) //账户禁用
export const authorize=(data)=>get(`role/selectList/1/authorize`,data) //角色授权
export const permissionList=(data)=>get(`account/permission`,data) //账户权限信息



