import {get,post,Del,put} from '@/utils/request'
export const imgURL ='http://81.68.87.225:9809/'; //图片
export const imgUrl ='https://wx.duomirabbit.com/path/'; //图片
export const fileURL ='https://wx.ywcq.com/pyzzyph/wx/file/fileUpload'; //上传


// 全局搜索
// export const getSearchList=(data)=>get(`system/dynamicinfomation/searchList`,data) //搜索
export const getSearchList=(data)=>get(`system/serch/indexSerch`,data) //全局搜索
export const getSearchInfo=(data)=>get(`system/serch/indexSerchDetail`,data) //搜索详情

// 动态资讯
export const dynamicinfomationList=(data)=>get(`system/dynamicinfomation/listPc`,data) //列表
export const dynamicinfomationInfo=(dynamicinfomationId,data)=>get(`system/dynamicinfomation/${dynamicinfomationId}`,data) //详情
export const dynamicinfomationListOther=(data)=>get(`system/dynamicinfomation/listOther`,data) //其他推荐（特殊）

// 关于我们
export const aboutasList=(data)=>get(`system/aboutas/listPc`,data) // 关于我们列表
export const getAboutasInfo=(aboutasId,data)=>get(`system/aboutas/${aboutasId}`,data) // 关于我们详情

// 酒店
export const hotelList=(data)=>get(`system/hotel/listPc`,data) // 酒店列表
export const hotelInfo=(hotelId,data)=>get(`/system/hotel/${hotelId}`,data) // 酒店列表

// banner
export const bannerList=(data)=>get(`system/Bannerow/listPc`,data) // banner列表

// 票务
export const ticketList=(data)=>post(`system/ticket/list`,data) // 票务

// 天气列表
export const weatherList=(data)=>get(`customer/situation/weather`,data) // 天气列表
// 客情列表
export const situationList=(data)=>get(`/customer/situation/getNum`,data) // 客情列表

// 度假区资源列表
export const historyList=(data)=>get(`/system/history/listPc`,data) // 度假区资源列表

// 景点
export const productList=(data)=>get(`system/sightseeing/listPc`,data) // 景点列表


// 特色产品
export const getproduct=(data)=>get('system/product/list',data)// 回显数据
export const detailsproduct=(data)=>get('system/product/findById',data)// 详情

// 票务预约
export const getticketing=(data)=>get('system/reservation/list',data)// 回显数据

// 联系我们
export const usList=(data)=>get('system/us/list',data)// 联系我们
export const consultAdd=(data)=>post('system/consult/add',data)// 投诉建议


// 人文历史
export const gethistory=(data)=>get('system/history/list',data)// 回显数据
export const detailshistory=(data)=>get('system/history/findById',data)// 详情

// 景点游览
export const getvisit=(data)=>get('system/sightseeing/list',data)// 回显数据
export const detailsvisit=(data)=>get('system/sightseeing/findById',data)// 详情


// 酒店民宿
export const getlefthotel=(data)=>post('system/hotel/findIndexByZero',data)// 回显左侧数据
export const getrighthotel=(data)=>get('system/hotel/list',data)// 回显左侧数据
export const jiudianxiangqing=(data)=>get('system/hotel/findById',data)// 回显左侧数据

//停车场
export const parkList=(data)=>post('system/park/list',data)// 停车场列表


// 详情数据
export const gettowlistinfo=(data)=>get('system/dynamicinfomation/list',data)// 动态资讯数据
export const idinfo=(data)=>get('system/dynamicinfomation/findById',data)// 详情数据

// 旅游攻略
export const getStrategy=(data)=>get('system/travelogue/list',data)// 游记攻略
export const detailsStrategy=(data)=>get('system/travelogue/findById',data)// 游记攻略详情
export const gettravelStrategy=(data)=>get('system/tips/list',data)// 旅游攻略
export const detailstravelStrategy=(data)=>get('system/tips/findById',data)// 旅游攻略详情

export const getWeather=(data)=>get('index/weather',data)// 首页气温,天气


// 用户管理
export const getUserlist=(data)=>get(`user/finduser`,data) // 用户列表
export const addUser=(data)=>post(`user/adduser`,data) // 用户添加
export const userDeluser=(data)=>post('user/deluser ',data); //用户删除
export const userResSet=(data)=>post('user/adminresetPassword ',data); //用户列表重置
export const userEditPassword=(data)=>post('user/resetPassword ',data); //用户修改密码


// 配置
export const getTreeList=(data)=>get(`dictionary/manage/treeList`,data) //管理列表树
export const addTreeList=(data)=>post(`dictionary`,data) //添加字典
export const infoTreeList=(dictionaryId,data)=>get(`dictionary/${dictionaryId}/editInfo`,data) //字典详情
export const editTreeList=(dictionaryId,data)=>put(`dictionary/${dictionaryId}`,data) //字典详情
export const delTreeList=(dictionaryId,data)=>Del(`dictionary/${dictionaryId}`,data) //字典详情
export const getSelectList=(dictionaryCode,data)=>get(`dictionary/${dictionaryCode}/selectList`,data) //字典值

// 资源管理
export const addFunction=(data)=>post(`function`,data) //资源添加
export const getTreesList=(data)=>get(`function/treeList/manage`,data) //资源树
export const delFunction=(data)=>Del(`function`,data) //资源树
export const FunctionList=(data)=>get(`function/treeList/select/authorize`,data) //资源树


// 角色管理
export const getRoleList=(data)=>get(`/role/listInfo`,data) //角色列表
export const getRoleTypeList=(data)=>get(`role/selectList/roleType`,data) //角色类型
export const RoleEditInfo=(roleId,data)=>get(`role/${roleId}/editInfo`,data) //角色编辑
export const roleEnable=(roleId,data)=>put(`role/${roleId}/enable`,data) //角色启用
export const roleDisable=(roleId,data)=>get(`role/${roleId}/disable`,data) //角色禁用
export const roleAdd=(data)=>post(`role`,data) //角色新增
export const rolePut=(roleId,data)=>put(`role/${roleId}`,data) //角色修改
export const roleDel=(roleId,data)=>Del(`role/${roleId}`,data) //角色删除

// 操作日志
export const getLogManageList=(data)=>get(`log/manageList`,data) //日志列表


