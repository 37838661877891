<template>
  <div :class="classObj" class="app-wrapper">
<!--    <div v-if="device==='mobile'&&sidebar.opened" class="drawer-bg" @click="handleClickOutside" />-->
<!--    <sidebar class="sidebar-container" />-->
<!--    <UserInfo></UserInfo>-->
<!--    <RightImg></RightImg>-->
    <div class="main-container">
<!--      <div :class="{'fixed-header':fixedHeader}">-->
<!--        <navbar />-->
<!--      </div>-->
      <div :class="{'nav_Top':true,'bgColor':isColor}">
        <div style="display: flex;align-items: center">
          <div class="nav_top_log">
            <img @click="$router.push({path:'/'})" v-show="!isColor" src="../assets/img/logo.png" alt="">
            <img @click="$router.push({path:'/'})" v-show="isColor" src="../assets/img/logoFFF.png" alt="">
<!--            <img src="../assets/img" alt="">-->
          </div>
<!--          <div v-show="!isimg?true:false" class="nav_Top_img">-->
<!--            <img src="https://s1.ax1x.com/2022/04/11/LVYqpR.png" width="100%" height="100%" alt="">-->
<!--          </div>-->
        </div>
        <div style="display: flex;align-items: center;">
          <sidebar v-show="isimg" />

        </div>

      </div>
      <app-main />

    </div>
  </div>
</template>

<script>
import { Navbar, Sidebar, AppMain, UserInfo, RightImg } from './components'
import {accountInfo} from '@/api'
import ResizeMixin from './mixin/ResizeHandler'

export default {
  name: 'Layout',
  components: {
    Navbar,
    Sidebar,
    AppMain,
    UserInfo,
    RightImg
  },
  data(){
    this.scrollDom = null;
    return {
      isimg:true,
      scrollDom:null,
      isColor:true
    }
  },
  mixins: [ResizeMixin],
  computed: {
    sidebar() {
      return this.$store.state.app.sidebar
    },
    device() {
      return this.$store.state.app.device
    },
    fixedHeader() {
      return this.$store.state.settings.fixedHeader
    },
    classObj() {
      return {
        hideSidebar: !this.sidebar.opened,
        openSidebar: this.sidebar.opened,
        withoutAnimation: this.sidebar.withoutAnimation,
        mobile: this.device === 'mobile'
      }
    }
  },
  watch:{
    $route(to,form) {
      console.log(to,form)
      if(to.fullPath != form.fullPath){
        this.scrollDom.scrollTop = 0
      }
      // if(to.path == '/hotelHomestay/hotelHomestayTwo'){
      //   this.isShow = false
      // }else{
      //   this.isShow = true
      // }
    },
    // deep: true
  },
  mounted() {
    // console.log(123465789)
    // this.info()

    this.$nextTick(()=>{
      // console.log(this.$refs.maxContextBox)
      // console.log(document.getElementsByClassName('maxContextBox')[0])
      this.handleScroll()
      // this.scrollDom = this.$refs.maxContextBox;
      // this.scrollDom = document.getElementsByClassName('maxContextBox')[0];
      this.initEvent(this.scrollDom)
    })
  },
  methods: {
    async info(){
      await accountInfo({}).then(res=>{
        // console.log(res)
        if(res.result == 0){

          sessionStorage.setItem('userUrl',res.data.accountPortrait)
        }
      })
    },
    handleClickOutside() {
      this.$store.dispatch('app/closeSideBar', { withoutAnimation: false })
    },
    handleScroll() {
      /* 获取回到顶部的位置元素 .content-container-top */
      // this.scrollDom = this.$refs.maxContextBox;
      this.scrollDom = document.getElementsByClassName('app-main')[0];

    },
    initEvent(dom) {
      // console.log(dom)
      dom.addEventListener('scroll', this.scrollHander);
    },
    scrollHander() {
      // console.log(this.scrollDom);
      const top = this.scrollDom.scrollTop;
      if(top<800){
        this.isColor = true
      }else{
        this.isColor = true
      }
      // console.log(top);
    }
  }
}
</script>

<style>
</style>
<style lang="scss" scoped>
  @import "~@/styles/mixin.scss";
  @import "~@/styles/variables.scss";


  .app-wrapper {
    @include clearfix;
    position: relative;
    height: 100%;
    width: 100%;
    &.mobile.openSidebar{
      position: fixed;
      top: 0;
    }
  }
  .drawer-bg {
    background: #000;
    opacity: 0.3;
    width: 100%;
    top: 0;
    height: 100%;
    position: absolute;
    z-index: 999;
  }

  .fixed-header {
    position: fixed;
    top: 0;
    right: 0;
    z-index: 9;
    width: calc(100% - #{$sideBarWidth});
    transition: width 0.28s;
  }

  .hideSidebar .fixed-header {
    width: calc(100% - 54px)
  }

  .mobile .fixed-header {
    width: 100%;
  }

  .nav_Top{
    display: flex;
    justify-content: space-between;
    //padding: 25px 80px 25px 55px;
    padding: 26px 80px 20px 55px;
    overflow: hidden;
    .nav_top_log{
      width: 172px;
      height: 74px;
      //margin: 15px 0;
      img{
        width: 100%;
        height: 100%;
        cursor: pointer;
      }
    }
    .nav_Top_img{
      width: 368px;
      height: 48px;
      margin-left: 20px;
    }
    .cutNavState{
      width: 39px;
      height: 32px;
      //background: red;
      margin-top: 5px;
      margin-left: 30px;
      img{
        width: 100%;
        height: 100%;
      }
    }
  }
  .bgColor{
    background: #007B8A;
  }
</style>
<style lang="less" scoped>

/deep/.bgColor .el-scrollbar .nav .submenu .el-submenu__title{
  color: #ffffff !important;
}
/deep/.bgColor .el-scrollbar .nav .submenuPadding{
  color: #ffffff !important;
}
/deep/.bgColor .el-scrollbar .nav .is-active{
  border: none;
  &.submenuPadding{
    background-image: url("../assets/img/navBg3_2.png") !important;
    //background-size: auto 100% !important;
    background-size: 49px 24px !important;
    background-repeat: no-repeat;
    background-position: left calc(50% + 8px);
  }
  .el-submenu__title{
    background-image: url("../assets/img/navBg3_2.png") !important;
    //background-size: auto 100% !important;
    background-size: 49px 24px !important;
    background-repeat: no-repeat;
    background-position: left calc(50% + 8px);
  }
}
/deep/.bgColor .el-scrollbar .nav {
  .el-menu-item,.el-submenu .el-submenu__title{
    &:hover{
      //color: #007B8A !important;
      background-image: url("../assets/img/navBg3_2.png") !important;
      background-size: 49px 24px !important;
      background-repeat: no-repeat;
      background-position: left calc(50% + 8px);
    }
  }
}
/deep/.bgColor .el-scrollbar .nav{
  .titleicon{
    .titleicon_1{
      background-color: #ffffff;
      border-color: white;
    }
    .H{
      color: white;
    }
    .titleicon_4{
      background: #ffffff;
      color: #007B8A;
      &:hover{
        background: #007B8A;
        border: 1px solid #ffffff;
        color: #ffffff;
      }
    }
    .titleicon_5{
      background: rgba(0,0,0,0);
      border-color: white;
      color: #ffffff;
      &:hover{
        background: #007B8A;
        border: 1px solid #ffffff;
        color: #ffffff;
      }
    }
  }

}
</style>
