<template>
  <div>
    <el-dialog width="500px" title="修改密码" :visible="true" :before-close="cancelBtn">
      <el-form>
        <el-form-item label="旧密码">
          <el-input type="text" v-model="oldpassword" autocomplete="off"></el-input>
        </el-form-item>
        <el-form-item label="新密码">
          <el-input type="password" v-model="newPassword" autocomplete="off"></el-input>
        </el-form-item>
        <el-form-item label="确认密码">
          <el-input type="password" v-model="affirmPassword" autocomplete="off"></el-input>
        </el-form-item>
      </el-form>
      <div slot="footer" class="dialog-footer">
        <el-button @click="cancelBtn">取 消</el-button>
        <el-button type="primary" @click="confirmPass">确 定</el-button>
      </div>
    </el-dialog>
  </div>
</template>

<script>
import {userEditPassword} from "@/api"
import {removeToken} from "@/utils/auth";

export default {
  name: "editPassword",
  props:['isShow','closeAlert'],
  data(){
    return {
      newPassword:'',
      affirmPassword:'',
      oldpassword:''
    }
  },
  mounted() {
  },
  methods:{
    // 关闭弹窗
    cancelBtn(){
      this.$emit('closeAlert',false)
    },
    async confirmPass(){
      if(this.newPassword.trim() != '' && this.affirmPassword.trim() != ''){
        if(this.newPassword == this.affirmPassword){
          await userEditPassword({
            oldpassword:this.oldpassword,
            newpassword:this.newPassword
          }).then(res=>{
            // console.log(res)
            if(res.code == 200){
              this.$message({
                message: '修改成功,请重新登陆',
                type: 'success'
              });
              removeToken()
              this.$router.push(`/`)
            }
          })
        }else{
          this.$message({
            message: '两次密码不一致,请重新输入',
            type: 'error'
          });
        }
      }

    }
  }
}
</script>

<style scoped>

</style>
