<template>
  <div id="app">
<!--    <div id="nav">-->
<!--      <router-link to="/">Home</router-link> |-->
<!--      <router-link to="/about">About</router-link>-->
<!--    </div>-->
    <router-view/>
  </div>
</template>

<script>
export default {
  name: 'App',
  // computed: {
  //   direction() {
  //     const viewDir = this.$store.state.viewDirection
  //     let tranName = ''
  //     if (viewDir === 'left') {
  //       tranName = 'view-out'
  //     } else if (viewDir === 'right') {
  //       tranName = 'view-in'
  //     } else {
  //       tranName = 'fade'
  //     }
  //     return tranName
  //   }
  // }
}
</script>

<style lang="less">
@import 'styles/global.css';

/deep/ #app{
  font-family: JDZY !important;
}
/deep/.paging-device{
  margin: 30px 0 70px -50px;
  display: flex;
  justify-content: center;
  .el-pager li{
    height: 35px;
    line-height: 35px;
  }
  .btn-prev{
    border-radius: 50%;
    box-shadow: 0px 3px 15px 2px rgba(235, 235, 235, 0.5);
    padding: 0;
    display: initial;
    width: 35px;
    height: 35px;
    margin-right: 40px;
    i{
      font-size: 16px;
    }
  }
  .btn-next{
    border-radius: 50%;
    box-shadow: 0px 3px 15px 2px rgba(235, 235, 235, 0.5);
    padding: 0;
    display: initial;
    width: 35px;
    height: 35px;
    margin-left: 40px;
    i{
      font-size: 16px;
    }
  }
}
//#app {
//  font-family: Avenir, Helvetica, Arial, sans-serif;
//  -webkit-font-smoothing: antialiased;
//  -moz-osx-font-smoothing: grayscale;
//  text-align: center;
//  color: #2c3e50;
//}
//
//#nav {
//  padding: 30px;
//
//  a {
//    font-weight: bold;
//    color: #2c3e50;
//
//    &.router-link-exact-active {
//      color: #42b983;
//    }
//  }
//}
//@import "./assets/css/AAA.css";
//@import "./assets/css/BBB";

//.fade-enter-active,
//.fade-leave-active {
//  transition: all .5s ease;
//}
//
//.fade-enter,
//.fade-leave-active {
//  opacity: 0;
//}
//
//.view-in-enter-active,
//.view-out-leave-active {
//  -webkit-animation-duration: .5s;
//  animation-duration: .5s;
//  -webkit-animation-fill-mode: both;
//  animation-fill-mode: both;
//  -webkit-backface-visibility: hidden;
//  backface-visibility: hidden;
//}
//
//.view-in-enter-active {
//  -webkit-animation-name: inRight;
//  animation-name: inRight;
//}
//
//.view-out-leave-active {
//  -webkit-animation-name: outLeft;
//  animation-name: outLeft;
//}

</style>
